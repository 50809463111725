import axios from "@/libs/request";


// 根据条件获取订单列表
export const GetOrderList = (pageNum, pageSize, data, config = {}) => {
  return axios.request({
    url: `/order/orderlist/page/${pageNum}/${pageSize}`,
    method: 'post',
    data,
    ...config,
  })
}

// 工作台商品订单管理详情
export const GetOrderDetail = (id, config = {}) => {
  return axios.request({
    url: `/order/orderlist/${id}`,
    method: "get",
    ...config,
  });
};

// 批量发货 // 接口废弃
export const BatchDelivery = (data, config = {}) => {
  return axios.request({
    url: `/order/set/delivery`,
    method: "post",
    data,
    ...config,
  });
};

/**
 * @param {Object} data 批量操作订单
 * @param {Number} data.op 1 关闭订单 2 删除订单 3 确认收货 4 批量发货
 * @param {String} data.infos[].logistics 物流公司名称
 * @param {String} data.infos[].logisticsNo 物流单号
 * @param {String} data.infos[].note 备注
 * @param {String} data.infos[].tradeNo 订单号
 * @param {Number} data.infos[].logisticsFee 运费 // 预留字段
 */
export const BatchOperationOrder = (data, config = {}) => {
  return axios.request({
    url: `/order/operation`,
    method: "post",
    data,
    ...config,
  });
};

// 卖家商品订单列表
export const GetSoldOrderList = (data, config = {}) => {
  const { page, size } = data;
  return axios.request({
    url: `/order/list/page/owner/${page}/${size}`,
    method: "post",
    data,
    ...config,
  });
};

// 工作台订单详情 操作信息

export const GetDetailOpList = (tradeNo, config = {}) => {
  return axios.request({
    url: `/order/op/record/${tradeNo}`,
    method: "get",
    ...config,
  });
};
