<template>
  <el-drawer
    :close-on-click-modal="false"
    title="订单详情"
    direction="rtl"
    :visible.sync="visible"
    style="text-align: left"
    size="80%"
  >
    <div class="drawer" style="padding: 20px 60px">
      <StepStatus :list="stepList" :active="curActiveStep" />
      <div class="status">
        <div class="status-label">
          当前的订单状态：
          <el-link
            :type="findStatusInfo(orderDetails.orderStatus).type"
            size="mini"
            :underline="false"
            style="cursor: default"
          >
            {{ findStatusInfo(orderDetails.orderStatus).label }}
          </el-link>
          <i
            class="el-icon-refresh"
            style="
              font-size: 20px;
              margin-left: 10px;
              vertical-align: bottom;
              cursor: pointer;
            "
            @click="viewDetail(orderDetails)"
          ></i>
        </div>
        <div class="btns"></div>
      </div>
      <el-row :gutter="30" style="text-align: left; left: 20px">
        <!-- 基本信息 -->
        <el-descriptions
          title="基本信息"
          :column="4"
          border
          direction="vertical"
        >
          <el-descriptions-item label="订单编号">
            {{ orderDetails.tradeNo || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="发货单流水号">
            {{ orderDetails.aaaaa || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="用户账号">
            {{ orderDetails.userAccountNo || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="支付方式">
            {{ $hashMap(PayTypeList, orderDetails.payWay) || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="配送方式">
            {{ orderDetails.logistics || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="物流单号">
            {{ orderDetails.logisticsNo || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="自动确认收货时间">
            {{ orderDetails.autoReceiptTime || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          v-if="orderDetails.consignee"
          title="收货人信息"
          border
          direction="vertical"
          style="margin-top: 40px"
        >
          <el-descriptions-item label="收货人">
            {{ orderDetails.consignee || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="手机号码">
            {{ orderDetails.phone || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="邮政编码">
            {{ orderDetails.postcode || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="收货地址">
            {{ orderDetails.address || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          title="商品信息"
          border
          direction="vertical"
          style="margin-top: 40px"
        />
        <el-table
          border
          show-summary
          :summary-method="getSummaries"
          :data="orderProductList"
          :header-cell-style="{
            backgroundColor: 'rgb(250, 250, 250)',
            'text-align': 'center',
          }"
          tooltip-effect="dark"
        >
          <el-table-column prop="name" label="商品图片" align="center">
            <template slot-scope="scope">
              <img
                style="width: 100px; height: auto"
                :src="scope.row.url"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="商品名称"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="price"
            label="价格"
            align="center"
          ></el-table-column>
          <el-table-column label="SKU信息" show-overflow-tooltip align="center">
            <template slot-scope="{ row }">
              <span style="white-space: nowrap">{{ row.skuInfo || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="count"
            label="数量"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="price"
            label="库存"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="price"
            label="小计"
            align="center"
          ></el-table-column>
        </el-table>
        <el-descriptions
          title="费用信息"
          border
          direction="vertical"
          style="margin-top: 40px"
        >
          <el-descriptions-item label="商品合计">
            ￥ {{ orderDetails.tradeAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="运费">￥ {{ orderDetails.logisticsFee || '0.00' }}</el-descriptions-item>

        </el-descriptions>
        <el-descriptions
          title="分润信息"
          border
          direction="vertical"
          style="margin-top: 40px"
        >
          <el-descriptions-item label="分润状态">
            <el-tag
              v-if="[2, 5, 6].includes(orderDetails.orderStatus)"
              size="mini"
              :type="orderDetails.distributed == 1 ? 'success' : 'warning'"
            >
              {{ $hashMap(profitTypes, orderDetails.distributed) }}
            </el-tag>
            <span v-else>-</span>
          </el-descriptions-item>
          <el-descriptions-item label="应分润">
            ￥ {{ orderDetails.expectedProfit }}
          </el-descriptions-item>
          <el-descriptions-item label="实分润">
            ￥ {{ orderDetails.actualProfit }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          title="操作信息"
          border
          direction="vertical"
          style="margin-top: 40px"
        />
        <HandleLog ref="log" />
      </el-row>
    </div>
  </el-drawer>
</template>

<script>
import { GetOrderDetail } from "@/api/service/order";
import { OrderStepList, OrderStatusNumber } from "@/libs/static";
import HandleLog from "./handleLog.vue";
import StepStatus from "./stepStatus.vue";
import { PayTypeList, ProfitTypes } from "@/libs/static";

export default {
  components: {
    // SingleSend,
    StepStatus,
    HandleLog,
  },
  data() {
    return {
      curActiveStep: undefined,
      PayTypeList,
      visible: false,
      stepList: [],
      orderDetails: {},
      orderProductList: [],
      profitTypes: ProfitTypes,
    };
  },

  methods: {
    init(data) {
      console.log("data:", data);
      this.visible = true;
      this.$nextTick(() => {
        this.viewDetail(data);
        this.$refs.log.init(data);
      });
    },
    findStatusInfo(status) {
      return OrderStatusNumber.find((item) => item.value == status) || {};
    },
    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      const { totalAmount } = this.orderDetails;
      columns.forEach((column, index) => {
        sums[index] = "";
        if (index === columns.length - 1) {
          sums[index] = `合计：${totalAmount}元`;
          return;
        }
      });
      return sums;
    },
    handelStepList() {
      const { orderDetails } = this;
      this.stepList = OrderStepList.map((item) => {
        return {
          ...item,
          time: orderDetails[item.timeProp],
        };
      });
    },
    async viewDetail(para) {
      const res = await GetOrderDetail(para.tradeNo || para.tradeno, {
        loading: {
          target: document.querySelector(".drawer"),
        },
      });
      const { logisticsInfo = "{}", objects } = res;
      const info = JSON.parse(logisticsInfo);
      this.orderDetails = { ...res, ...info, objects } || {};
      this.orderProductList = (objects || []).map((item) => {
        const { specsPriceBDTOList = [] } = item;
        return {
          ...item,
          skuInfo: specsPriceBDTOList
            .map((sku) => {
              return `${sku.specsName}:${sku.value}`;
            })
            .join(" "),
        };
      });
      this.curActiveStep =
        this.findStatusInfo(this.orderDetails.orderStatus).step || undefined;
      this.handelStepList();
    },
  },
};
</script>

<style lang="less" scoped>
.drawer {
  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
</style>
