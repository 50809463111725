<template>
  <el-table
    class="log-table"
    border
    :data="dataList"
    :header-cell-style="{
      backgroundColor: 'rgb(250, 250, 250)',
      'text-align': 'center',
    }"
    tooltip-effect="dark"
  >
    <el-table-column
      prop="operator"
      label="操作者"
      align="center"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      prop="createTime"
      label="操作时间"
      align="center"
    ></el-table-column>
    <el-table-column prop="orderStatus" label="订单状态" align="center">
      <template slot-scope="{ row }">
        {{ $hashMap(OrderStatusNumber, row.orderStatus) }}
      </template>
    </el-table-column>
    <el-table-column prop="payStatus" label="付款状态" align="center">
      <template slot-scope="{ row }">
        {{ $hashMap(OrderPayStatusNumber, row.payStatus) }}
      </template>
    </el-table-column>
    <el-table-column prop="deliveryStatus" label="发货状态" align="center">
      <template slot-scope="{ row }">
        {{ $hashMap(OrderShipStatusNumber, row.deliveryStatus) }}
      </template>
    </el-table-column>
    <el-table-column prop="note" label="备注" align="center"></el-table-column>
  </el-table>
</template>

<script>
import { GetDetailOpList } from "@/api/service/order";
import {
  OrderShipStatusNumber,
  OrderPayStatusNumber,
  OrderStatusNumber,
} from "@/libs/static";
export default {
  components: {},
  data() {
    return {
      OrderShipStatusNumber,
      OrderPayStatusNumber,
      OrderStatusNumber,
      dataList: [],
    };
  },
  methods: {
    init(data) {
      this.$nextTick(() => {
        this.getList(data);
      });
    },
    getList(data) {
      GetDetailOpList(data.tradeNo|| data.tradeno, {
        loading: { target: document.querySelector(".log-table") },
      }).then((res) => {
        this.dataList = res || [];
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
