<template>
  <div class="step">
    <el-steps :active="active" align-center>
      <el-step
        v-for="(item, idx) in list"
        :title="item.label"
        :key="item.label"
        :description="showDesc(item, idx)"
      ></el-step>
    </el-steps>
  </div>
</template>

<script>

export default {
  props: {
    active: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  computed: {
    showTips() {
      return this.list.some((item) => item.showTips);
    },
  },
  data() {
    return {};
  },
  methods: {
    showDesc(item, idx) {
      if (idx < this.active) {
        return item.time;
      }
      if (idx === this.active) {
        return item.tips;
      }
      return "";
    },
  },
};
</script>

<style lang="less" scoped>
.step {
  margin: 30px;
}
</style>
