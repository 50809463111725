<template>
  <div>
    <!-- breadcrumb面包屑导航区域 -->
    <!-- 首页/活动管理/活动列表/活动详情 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="orderStatus">
            <div style="display: inline-block" class="divSpan">订单状态：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.orderStatus"
                :options="OrderStatus"
                :optionKey="OrderStatuskeys"
                Splaceholder="请选择搜索的状态"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="orderNo">
            <div style="display: inline-block" class="divSpan">订单编号：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入搜索的编号"
                v-model.trim="queryinfo.orderNo"
                clearable
                @clear="getList"
                style="width: 220px"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="subject">
            <div style="display: inline-block" class="divSpan">产品名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入搜索的产品"
                v-model.trim="queryinfo.subject"
                clearable
                @clear="getList"
                style="width: 180px"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="payId">
            <div style="display: inline-block" class="divSpan">购买者ID：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.payId"
                clearable
                @clear="getList"
                style="width: 200px"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="payTime">
            <div style="display: inline-block" class="divSpan">支付日期：</div>
            <div style="display: inline-block">
              <el-date-picker
                type="datetimerange"
                v-model="queryinfo.payTime"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="截止日期"
                @change="dateSerach"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item prop="amountLimit">
            <div style="display: inline-block" class="divSpan">金额范围：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.amountLimit"
                :options="amountLimitList"
                :optionKey="OrderStatuskeys"
                Splaceholder="请选择金额范围"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="seachChange">
            查询
          </el-button>
          <el-button type="info" icon="el-icon-refresh" @click="clean">
            重置
          </el-button>
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="OrderList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="tradeNo"
            label="订单编号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="subject"
            label="订单产品"
            min-width="150px"
          ></el-table-column>

          <el-table-column
            prop="userId"
            label="购买者ID"
            sortable
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            min-width="150px"
          ></el-table-column>

          <!-- <el-table-column
            prop="accountName"
            label="购买用户"
            sortable
            min-width="150px"
          ></el-table-column> -->
          <el-table-column label="支付方式" min-width="100px">
            <template slot-scope="{ row }">
              {{ $hashMap(PayTypeList, row.payWay) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="payTime"
            label="支付时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="tradeAmount"
            label="订单金额(元)"
            min-width="100px"
          ></el-table-column>
          <el-table-column label="订单状态" min-width="100px">
            <template slot-scope="{ row }">
              {{ $hashMap(OrderStatusNumber, row.orderStatus) }}
            </template>
          </el-table-column>
          <el-table-column label="分润状态" min-width="100px">
            <template slot-scope="{ row }">
              <el-tag
                v-if="[2, 5, 6].includes(row.orderStatus)"
                size="mini"
                :type="row.distributed == 1 ? 'success' : 'warning'"
              >
                {{ $hashMap(profitTypes, row.distributed) }}
              </el-tag>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="balanceAmount"
            label="余额支付(元)"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="cashAmount"
            label="现金支付(元)"
            min-width="100px"
          ></el-table-column> -->

          <el-table-column label="操作" min-width="150px" fixed="right">
            <template slot-scope="scope">
              <el-link
                size="mini"
                type="primary"
                @click="viewDetail(scope.row)"
              >
                查看详情
              </el-link>
              <el-link
                style="margin-left: 10px"
                size="mini"
                type="danger"
                @click="refundMoney(scope.row)"
                v-show="
                  scope.row.isTool &&
                  scope.row.orderStatus === 2 &&
                  scope.row.orderStatus !== 3
                "
              >
                退款
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 查看对话框-->
    <Detail ref="detail" />
  </div>
</template>
<script>
import ELselect from "@/components/myself/el_select";
import { GetOrderList } from "@/api/service/order";
import { createRefundWorkSheet } from "@/api/cloudmarket/finance";
import { deleteDialog } from "@/libs/confirmDialog";
import Detail from "./orderDetail.vue";
import {
  PayTypeList,
  SearchMoneyList,
  OrderStatusList,
  OrderStatusNumber,
  ProfitTypes
} from "@/libs/static";

export default {
  components: { ELselect, Detail },
  data() {
    return {
      profitTypes: ProfitTypes,
      PayTypeList,
      OrderStatusNumber,
      queryinfo: {
        createtime: "",
        amountLimit: "",
        subject: "",
        // accountName: '',
        orderNo: "",
        orderStatus: undefined,
        pageNum: 1,
        pageSize: 20,
        payTime: "",
        payId: "",
      },
      total: 0,
      OrderList: [],
      OrderStatus: OrderStatusList,
      amountLimitList: SearchMoneyList,
      OrderStatuskeys: {
        value: "value",
        label: "label",
        label2: "",
      },

      viewForm: [],
      viewDialogVisible: false,
      OrderObjects: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let time1 = this.queryinfo.payTime ? this.queryinfo.payTime[0] : "";
      let time2 = this.queryinfo.payTime ? this.queryinfo.payTime[1] : "";

      let querise = {
        payBegin: time1,
        payEnd: time2,
        orderStatus: this.queryinfo.orderStatus,
        amount: this.queryinfo.amountLimit,
        tradeNo: this.queryinfo.orderNo,
        subject: this.queryinfo.subject,
        userId: this.queryinfo.payId,
      };

      const res = await GetOrderList(
        this.queryinfo.pageNum,
        this.queryinfo.pageSize,
        querise,
        {
          loading: { target: document.querySelector(".el-table")}
        }
      );
      this.OrderList = res.list;
      this.total = res.total;
    },
    clean() {
      this.$refs.queryinfoRef.resetFields();
      this.getList();
    },
    dateSerach() {
      this.getList();
    },
    seachChange() {
      this.queryinfo.pageNum = 1;
      this.getList();
    },
    handleSizeChange(newSize) {
      this.queryinfo.pageSize = newSize;
      this.getList();
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pageNum = newPage;
      this.getList();
    },

    async viewDetail(row) {
      this.$refs.detail.init(row);
    },
    async refundMoney(para) {
      const Result = await deleteDialog("确定对此订单进行退款处理?", this);
      if (Result === "confirm") {
        const res = await createRefundWorkSheet(para.tradeNo);
        if (res.status !== 200) return this.$message.error("操作失败");
        if (res.data.status !== 200)
          return this.$message.error(res.data.message);
        this.$message.success("操作成功");
        this.getList();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}

/deep/ .el-form-item__content {
  margin-right: 30px;
}
</style>
