// 支付方式
export const PayTypeList = [
  {
    value: "BALANCE",
    label: "余额",
  },
  {
    value: "ALIPAY",
    label: "支付宝",
  },
  {
    value: "WXPAY",
    label: "微信",
  },
  {
    value: "MIXPAY",
    label: "余额+支付宝",
  },
  {
    value: "WXXPAY",
    label: "余额+微信",
  },
];

// 选择搜索金额范围
export const SearchMoneyList = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "100元以下",
  },

  {
    value: 2,
    label: "100-200元",
  },
  {
    value: 3,
    label: "200-500元",
  },
  {
    value: 4,
    label: "500-1000",
  },
  {
    value: 5,
    label: "1000元以上",
  },
];
// 订单状态
export const OrderStatusList = [
  {
    value: undefined,
    label: "全部",
  },
  {
    value: 0,
    label: "待支付",
  },

  {
    value: 2,
    label: "已完成",
  },
  {
    value: 1,
    label: "已取消",
  },
  {
    value: 4,
    label: "退款中",
  },
  {
    value: 3,
    label: "已退款",
  },

  {
    value: 5,
    label: "待发货",
  },
  {
    value: 6,
    label: "待收货",
  },
];
// 订单状态 number string
export const OrderStatusNumber = [
  {
    value: "0",
    label: "待付款",
    type: "warning",
    step: 1,
  },
  {
    value: "1",
    label: "已关闭",
    type: "warning",
    step: "",
  },
  {
    value: "2",
    label: "已完成",
    type: "warning",
    step: 5,
  },
  {
    value: "3",
    label: "已退款",
    type: "success",
    step: "",
  },
  {
    value: "4",
    label: "退款中",
    type: "info",
    step: "",
  },
  {
    value: "5",
    label: "待发货",
    type: "danger",
    step: 2,
  },
  {
    value: "6",
    label: "待收货",
    type: "info",
    step: 3,
  },
  {
    value: "7",
    label: "已支付",
    type: "success",
    step: "",
  },
];

// step list 列表
export const OrderStepList = [
  {
    label: "提交订单",
    timeProp: "createTime",
    time: "",
    tips: "",
    status: 0,
  },
  {
    label: "支付订单",
    timeProp: "gmtPayment",
    time: "",
    tips: "待支付",
    status: 5,
  },
  {
    label: "平台发货",
    timeProp: "shipTime",
    time: "",
    tips: "待发货",
    status: 6,
  },
  {
    label: "确认收货",
    timeProp: "receiptTime",
    time: "",
    tips: "待收货",
    status: 111, // 无这个状态，只是为了显示
  },
  {
    label: "完成订单",
    timeProp: "finishTime",
    time: "",
    tips: "",
    status: 2,
  },
];

// 买家订单状态 列表搜索及详情页
export const OrderStatusListBuyer = [
  {
    value: "",
    label: "全部",
    type: "",
  },
  {
    value: "WAIT_BUYER_PAY",
    label: "待付款",
    type: "warning",
  },
  {
    value: "TRADE_WAIT_DELIVER",
    label: "待发货",
    type: "warning",
  },
  {
    value: "TRADE_WAIT_RECEIPT",
    label: "待收货",
    type: "warning",
  },
  {
    value: "TRADE_FINISHED",
    label: "已完成",
    type: "success",
  },
  {
    value: "TRADE_CLOSED",
    label: "已关闭",
    type: "info",
  },
  {
    value: "TRADE_IN_REFUND",
    label: "退款中",
    type: "danger",
  },
  {
    value: "TRADE_REFUND",
    label: "已退款",
    type: "info",
  },
];
// 发货状态
export const OrderShipStatusNumber = [
  {
    value: "0",
    label: "未发货",
    type: "danger",
  },
  {
    value: "1",
    label: "已发货",
    type: "danger",
  },
  {
    value: "2",
    label: "已收货",
    type: "danger",
  },
];
// 订单pay status number string
export const OrderPayStatusNumber = [
  {
    value: "0",
    label: "未支付",
    type: "danger",
  },
  {
    value: "1",
    label: "已支付",
    type: "danger",
  },
  {
    value: "2",
    label: "已退款",
    type: "danger",
  },
  {
    value: "3",
    label: "退款中",
    type: "danger",
  },
];
// 分润状态
export const ProfitTypes = [
  {
    label: "未分润",
    value: "0",
  },
  {
    label: "已分润",
    value: "1",
  },
]
